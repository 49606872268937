import React from 'react';
import Layout from '../components/Layout.js';
import { illustrations, figureDrawings, videos, extras } from '../data/galleryData.js';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import "../styles/Gallery.css";
import youtubeLogo from "../img/yt_logo_rgb_light.png";

export default () => {
  const [activeTab, setActiveTab] = React.useState('portfolio');
  const [currentVideo, setCurrentVideo] = React.useState(null);
  const lightboxOptions = {
    buttons: {
      showDownloadButton: false
    }
  };

  return (   
    <SimpleReactLightbox>
    <Layout>
      <div className="gallery content">
        <div className="aux-info">
          <div className="with-margin">
            <div role="tablist">
              <div className={activeTab === 'portfolio' ? 'active' : null}><button role="tab" onClick={() => setActiveTab("portfolio")}>Artist's Portfolio</button></div>
              <div className={activeTab === 'animation' ? 'active' : null}><button role="tab" onClick={() => setActiveTab("animation")}>Animation</button></div>
              <div className={activeTab === 'extras' ? 'active' : null}><button role="tab" onClick={() => setActiveTab("extras")}>Extras</button></div>
            </div>
                {activeTab === "portfolio" ? (<div role="tabpanel" className="aux-info-item portfolio-tab">
                <SRLWrapper options={lightboxOptions}>
                  <h2>Life Drawing</h2>
                  <div className="thumbnails-container scale-interaction">
                    {figureDrawings.map(file => (
                    <div className="art-thumb-container">
                      <div className="hover-overlay">
                        <p>{file.name}</p>
                      </div>
                      <a href={`/portfolio/${file.filename}.jpg`} data-attribute="SRL">
                        <img src={`/portfolio-thumbs/${file.filename}.jpg`} alt={file.description}/>
                      </a>
                    </div>))}
                  </div>
                  <h2>Illustration & Concept Art</h2>
                  <div className="thumbnails-container scale-interaction">
                    {illustrations.map(file => (
                    <div class="art-thumb-container">
                      <div className="hover-overlay">
                        <p>{file.name}</p>
                      </div>
                      <a href={`/portfolio/${file.filename}.jpg`} data-attribute="SRL">
                        <img src={`/portfolio-thumbs/${file.filename}.jpg`} alt={file.description}/>
                      </a>
                    </div>))}
                  </div>
                </SRLWrapper>
              </div>) : null}

              {activeTab === "animation" ? (<div role="tabpanel" className="aux-info-item">
                <div className="video-container">
                  {currentVideo ? (<div>
                    <h2>{currentVideo.name}</h2>
                    <video controls key={currentVideo.filename}>
                      <source src={`${process.env.GATSBY_AWS_S3_BASE_URL}/videos/${currentVideo.filename}.${currentVideo.extension || 'mp4'}`} type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                    <p className="description">{currentVideo.description}</p>
                  </div>) : ""}
                  
                  <div className="video-thumbs">
                    {videos.map(file => (<div className="video-thumb-container">
                      <div className="play-overlay">
                        <p>{file.name}</p>
                      </div>
                      <button onClick={() => setCurrentVideo(file)}><img src={`${process.env.GATSBY_AWS_S3_BASE_URL}/videos/thumbs/${file.filename}-thumb.jpg`} alt={file.description}/></button>
                    </div>))}
                    <p className="youtube-link">See more: <a href="https://www.youtube.com/channel/UCYV0ZZuG-QLWlgtVOQEhcUA"><img src={youtubeLogo}/></a></p>
                  </div>
                </div>
              </div>) : null}
              
              {activeTab === "extras" ? (<div role="tabpanel" className="aux-info-item">
                <p>This section for concept and promotional art for <i>The Starless Land</i> is a stub for now because most character/set designs aren't locked in yet.</p>
                <SRLWrapper>
                  <div className="thumbnails-container scale-interaction">
                    {extras.map(file => (<a href={`/extras/${file.filename}.jpg`} data-attribute="SRL"><img src={`/extras-thumbs/${file.filename}.jpg`} alt={file.description}/></a>))}
                    </div>
                </SRLWrapper>
                </div>) : null}
          </div>
        </div>
      </div>
    </Layout>
    </SimpleReactLightbox>
  );
}
    